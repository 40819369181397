import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useClientConfig } from '/machinery/ClientConfig'
import imageUrlBuilder from '@sanity/image-url'

import styles from './BlurredImageMask.css'

export function BlurredImageMask({
  image,
  children = undefined,
  sizes = undefined,
  layoutClassName = undefined,
  ...rest
}) {
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const loading = isInViewport ? 'eager' : 'lazy'

  const { sanity } = useClientConfig()
  const builder = imageUrlBuilder(sanity.clients.readOnly)

  const firstBlur = urlFor(image).width(500).blur(50).url()
  const secondBlur = urlFor(image).width(500).blur(250).url()

  return (
    <div ref={isInViewportRef} className={cx(styles.component, layoutClassName)} {...rest}>
      {children}
      <PresentationImage
        src={firstBlur}
        layoutClassName={styles.blurLayout}
        objectFit='cover'
        {...{ loading, sizes }}
      />
      <PresentationImage
        src={secondBlur}
        layoutClassName={styles.blurLayout}
        objectFit='cover'
        {...{ loading, sizes }}
      />
    </div>
  )

  function urlFor(source) {
    return builder.image(source)
  }
}

function PresentationImage({ src, sizes, loading, objectFit, layoutClassName }) {
  return (
    <img
      alt=''
      role='presentation'
      style={{ objectFit }}
      className={cx(styles.componentPresentationImage, layoutClassName)}
      {...{ src, loading, sizes }}
    />
  )
}
